.user-profile {
  max-width: 1012px;
  margin: 0 auto;
  padding: 24px;

  .profile-header {
    display: flex;
    gap: 24px;
    padding: 24px;
    margin-bottom: 24px;
    background-color: #ffffff;
    border-radius: 6px;

    .profile-avatar {
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .profile-info {
      flex: 1;

      h1 {
        margin: 0;
        line-height: 1.25;
      }

      .MuiButton-root {
        margin: 16px 0;
      }
    }

    .profile-stats {
      display: flex;
      gap: 16px;
      margin: 16px 0;

      .stat-link {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #24292f;

        &:hover {
          color: #0969da;
        }

        .MuiSvgIcon-root {
          font-size: 1rem;
        }
      }
    }

    .profile-links {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 16px;

      .profile-link {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #57606a;
        font-size: 0.875rem;

        &:hover {
          color: #0969da;
        }

        .MuiSvgIcon-root {
          font-size: 1rem;
        }
      }
    }
  }

  .profile-content {
    padding: 24px;
    background-color: #ffffff;
    border-radius: 6px;

    .bio-section {
      margin-bottom: 24px;

      p {
        margin: 8px 0;
        color: #24292f;
      }
    }

    .skills-section {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 24px;

      .skill-chip {
        background-color: #f6f8fa;
        border-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
